import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light"
import Feature from "components/features/DashedBorderSixFeaturesRentals";

import Footer from "../components/footers/FiveColumnDark";


export default () => (
    <AnimationRevealPage>
        <Header />
        <Feature />
        <Footer />
    </AnimationRevealPage>

);
