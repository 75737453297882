import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import Testi from "components/testimonials/ThreeColumnWithProfileImage";
const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Testi />
      <ContactUsForm />
      <ContactDetails
        cards={[



          {
            title: "Ghaziabad",
            description: (
              <>
                <Address>
                  <AddressLine>Auto Components</AddressLine>
                </Address>
              </>
            )
          },
          {
            title: "Bawal",
            description: (
              <>
                <Address>
                  <AddressLine>Forging</AddressLine>
                </Address>

              </>
            )
          },
          {
            title: "Gajraula",
            description: (
              <>
                <Address>
                  <AddressLine>Auto Components</AddressLine>

                </Address>

              </>
            )
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
