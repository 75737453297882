import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as FacebookIcon } from "feather-icons/dist/icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "feather-icons/dist/icons/twitter.svg";
import { ReactComponent as Instagram } from "feather-icons/dist/icons/instagram.svg";
import { ReactComponent as Phone } from "feather-icons/dist/icons/phone-call.svg";

import { ReactComponent as Mail } from "feather-icons/dist/icons/mail.svg";
const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-8 pb-10`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition-default duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-sm font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs  font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition-default duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``
const GoogleMaps = tw.iframe`overflow-hidden rounded-3xl`
const Divider = tw.div`my-8 border-b-2 border-gray-800`
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoText>Parishudh Machines Pvt. Ltd.</LogoText>
            </LogoContainer>
            <CompanyAddress>
              Headquarters

              Monday - Saturday 9:00 - 18:30

              C-26 Industrial Area

              Meerut Road ,Ghaziabad

            </CompanyAddress>

            <SocialLinksContainer>

              <SocialLink href="tel:+91 9540996029">
                <Phone />

              </SocialLink>
              <SocialLink href="mailto:Custcare@goindi.org">
                <Mail />
              </SocialLink>
            </SocialLinksContainer>

          </CompanyColumn>
          <CompanyColumn>
            <ColumnHeading>Map</ColumnHeading>
            <LinkList>
              <GoogleMaps src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.1481889774604!2d77.43226828358313!3d28.685213418882693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf1a66cd74d57%3A0x3d4209124477f923!2sGoindi!5e0!3m2!1sen!2sin!4v1705407541648!5m2!1sen!2sin"
                width="90%" height="200"
                allowfullscreen="true"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></GoogleMaps>
            </LinkList>
          </CompanyColumn>
          <CompanyColumn>
            <ColumnHeading>Works</ColumnHeading>
            <LinkList>
              Ghaziabad (Auto Components)
              <br></br>
              Bawal(Forging)
              <br></br>
              Gajraula (Auto Components)
            </LinkList>
          </CompanyColumn>



          <CompanyColumn>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem  >
                <Link href="tel:+91 9540996029">+919540996029</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:custcare@goindi.org">custcare@goindi.org</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#"></Link>
              </LinkListItem>


            </LinkList>
          </CompanyColumn>
        </FiveColumns>

        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright {new Date().getFullYear()}, Goindi Group.</CopyrightNotice>
          <CompanyInfo>A TS 16949 2002 CERTIFIED COMPANY.</CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>


    </Container >


  );
};
