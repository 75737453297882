import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

import { ReactComponent as Phone } from "feather-icons/dist/icons/phone-call.svg";

import { ReactComponent as Mail } from "feather-icons/dist/icons/mail.svg";
const Subheading = tw(SubheadingBase)` text-2xl font-black  mb-0`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer my-3  shadow-xl  inline-block p-5 rounded-lg bg-gray-200 text-gray-900 hover:bg-gray-300 transition-default duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Our Contact",
  heading = "",


}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>

          <SocialLinksContainer>

            <SocialLink href="tel:+91 9540996029">
              <Phone />
              +91 9540996029
            </SocialLink>
            <SocialLink href="mailto:Custcare@goindi.org">
              <Mail />
              Custcare@goindi.org
            </SocialLink>
          </SocialLinksContainer>


        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
